import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import styles from "./EmployeeFormModal.module.scss";
import { IFormInput } from "../../types/employees";
import {
  getEditEmployee,
  getIsEmployeeModalOpen,
  toggleEmployeeModal,
} from "../../features/employees/employeesSlice";
import { FaCalendarAlt } from "react-icons/fa";
import moment from "moment";

type Props = {
  handleSaveEmployee: (data: IFormInput) => void;
};

const EmployeeFormModal = ({ handleSaveEmployee }: Props) => {
  const dispatch = useAppDispatch();
  const editEmployee = useAppSelector(getEditEmployee);
  const isEmployeeModalOpen = useAppSelector(getIsEmployeeModalOpen);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      employee_code: "",
      name: "",
      phone: "",
      address: "",
      image: null,
      amount: 0,
    },
  });

  const handleOnSubmit: SubmitHandler<IFormInput> = async (data: any) => {
    setIsLoading(true);
    await handleSaveEmployee(data);
    handleCloseFormModal();
    setIsLoading(false);
  };

  const handleCloseFormModal = () => {
    reset();
    dispatch(toggleEmployeeModal(false));
  };

  useEffect(() => {
    if (editEmployee && editEmployee.id) {
      reset({
        employee_code: editEmployee.employee_code,
        name: editEmployee.name,
        phone: editEmployee.phone,
        address: editEmployee.address,
        image: null,
        amount: editEmployee.amount,
      });
    } else {
      reset({
        employee_code: "",
        name: "",
        phone: "",
        address: "",
        image: null,
        amount: null,
        created_at: moment().format("YYYY-MM-DD"),
      });
    }
  }, [editEmployee, isEmployeeModalOpen]);

  return (
    <>
      {isEmployeeModalOpen === true && (
        <div className={styles.EmployeeFormModal}>
          <div className="relative h-[100vh] w-[100vw] overflow-y-scroll lg:overflow-auto top-0 lg:top-10 lg:mx-auto p-5 border lg:w-96 lg:h-fit shadow-lg lg:rounded-md bg-white">
            <div className="mt-3">
              <h3 className="text-2xl text-center font-bold leading-6 text-primary-1">
                કામદાર ની માહિતી
              </h3>
              <div className="mt-2 py-3">
                <form onSubmit={handleSubmit(handleOnSubmit)}>
                  <div className="grid gap-4">
                    <div className="mt-6">
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-14 w-full relative rounded">
                        <input
                          className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          type="text"
                          placeholder="કાર્ડ નંબર દાખલ કરો"
                          {...register("employee_code", { required: false })}
                        />
                      </div>
                      {errors.employee_code &&
                        errors.employee_code.type === "required" && (
                          <span className="text-red-500">
                            કાર્ડ નંબર દાખલ કરો
                          </span>
                        )}
                    </div>

                    <div className="mt-3">
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-14 w-full relative rounded">
                        <input
                          className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          type="text"
                          placeholder="કામદાર નામ દાખલ કરો"
                          {...register("name", { required: true })}
                        />
                      </div>
                      {errors.name && errors.name.type === "required" && (
                        <span className="text-red-500">
                          કામદાર નામ દાખલ કરો
                        </span>
                      )}
                    </div>

                    <div className="mt-3">
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-14 w-full relative rounded">
                        <input
                          className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          type="text"
                          placeholder="મોબાઇલ નંબર દાખલ કરો"
                          {...register("phone", { required: false })}
                        />
                      </div>
                      {errors.phone && errors.phone.type === "required" && (
                        <span className="text-red-500">
                          મોબાઇલ નંબર દાખલ કરો
                        </span>
                      )}
                    </div>

                    <div className="mt-3">
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-14 w-full relative rounded">
                        <input
                          className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          type="number"
                          pattern="-?[0-9]*"
                          step={"any"}
                          placeholder="કામદારનો 1 કલાક નો પગાર દાખલ કરો"
                          {...register(`amount`, {
                            required: true,
                            min: 0,
                          })}
                          onFocus={(e) =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            )
                          }
                        />
                      </div>
                      {errors.phone && errors.phone.type === "required" && (
                        <span className="text-red-500">
                          કામદારનો 1 કલાક નો પગાર દાખલ કરો
                        </span>
                      )}
                    </div>

                    <div className="mt-3">
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-28 w-full relative rounded">
                        <textarea
                          rows={5}
                          className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          placeholder="કામદારનુ એડ્રેસ દાખલ કરો"
                          {...register("address", { required: false })}
                        />
                      </div>
                      {errors.address && errors.address.type === "required" && (
                        <span className="text-red-500">
                          કામદારનુ એડ્રેસ દાખલ કરો
                        </span>
                      )}
                    </div>

                    {!editEmployee && (
                      <div className="mt-3">
                        <div className="flex items-center bg-gradient-to-r from-primary-1 to-primary-2 rounded pr-[2px] h-[44px]">
                          <FaCalendarAlt className="text-white mx-2" />
                          <input
                            className="bg-white text-primary-1 placeholder:text-primary-2 font-semibold text-xl focus:outline-none p-2 rounded flex-grow h-[40px]"
                            type="date"
                            {...register("created_at", {
                              required: true,
                            })}
                            placeholder="તારીખ"
                          />
                        </div>
                      </div>
                    )}

                    <div className="mt-3 text-center">
                      <button
                        className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? "રાહ જુઓ..." : "આગળ વધો"}
                      </button>
                    </div>

                    <div className="mt-1 text-center">
                      <button
                        className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                        type="button"
                        onClick={handleCloseFormModal}
                        disabled={isLoading}
                      >
                        બંધ કરો
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EmployeeFormModal;
